import React from 'react';
import { Card, CardContent, Typography, CardActions, Button, Rating, Grid } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';

function Performer({ name, rate, description, image, rating, location }) {
  return (
    <Card>
      <img
        src={image}
        alt={name}
        style={{
          height: '250px',
          width: '250px',
          objectFit: 'cover',
          borderRadius: '50%',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginBottom: '10px',
        }}
      />
      
      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
          {name}
        </Typography>
        <Rating name="read-only" value={rating} precision={0.5} readOnly />
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="body2" color="textSecondary">
              {rate}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="textSecondary">
              {location} <LocationOnIcon />
            </Typography>
          </Grid>
        </Grid>
        <Typography variant="body2" color="textSecondary">
          {description}
        </Typography>

      </CardContent>
      <CardActions>
        <Button size="small">View Full Details</Button>
      </CardActions>
    </Card>
  );
}

export default Performer;
