import React, { useState } from 'react';
import { Typography, Card, CardContent, TextField, Button, Grid } from '@mui/material';

function Profile(props) {
    const { user } = props;

    // Initialize state for editable fields
    const [firstName, setFirstName] = useState(user.firstName || '');
    const [lastName, setLastName] = useState(user.lastName || '');
    const [isEditing, setIsEditing] = useState(false);

    // Function to toggle editing mode
    const toggleEditing = () => {
        setIsEditing(!isEditing);
    };

    // Function to handle cancel button click
    const handleCancel = () => {
        // Reset the fields to their original values
        setFirstName(user.firstName || '');
        setLastName(user.lastName || '');
        setIsEditing(false);
    };

    // Function to handle save button click
    const handleSave = () => {
        // Perform the update logic here (e.g., make an API call to update user information)
        // After successful update, setIsEditing(false) to exit editing mode
        // You can update the user object with the new values if needed
        setIsEditing(false);
    };

    return (
        <Card>
            <CardContent>
                <Typography variant="h5">Profile</Typography>
                <Typography variant="body1">Email: {user.signInDetails?.loginId}</Typography>
                <form onSubmit={handleSave}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="First Name"
                                variant="outlined"
                                fullWidth
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Last Name"
                                variant="outlined"
                                fullWidth
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    {isEditing ? (
                        <Grid container spacing={2}>
                            <Grid item>
                                <Button variant="contained" color="primary" type="submit">
                                    Save
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" color="inherit" onClick={handleCancel}>
                                    Cancel
                                </Button>

                            </Grid>
                        </Grid>
                    ) : (
                        <Button
                            variant="contained" // Add this line
                            color="primary"
                            onClick={toggleEditing}
                        >
                            Edit
                        </Button>
                    )}

                </form>
            </CardContent>
        </Card>
    );
}

export default Profile;
