import React, { useState, useEffect } from 'react';
import { Container, Tab, Tabs, Box, TextField, Button, Typography } from '@mui/material';
import { generateClient } from 'aws-amplify/api';
import { signUp, confirmSignUp, signIn, getCurrentUser } from 'aws-amplify/auth';
import { createPerformer } from '../graphql/mutations'; // Adjust the import path as necessary

function LoginSignUp() {
    const [activeTab, setActiveTab] = useState(0);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [confirmationCode, setConfirmationCode] = useState('');
    const [feedbackMsg, setFeedbackMsg] = useState('');
    const [loggedInUser, setLoggedInUser] = useState(null);

    useEffect(() => {
        // Check if a user is already authenticated on page load
        checkAuthenticatedUser();
    }, []);

    const checkAuthenticatedUser = async () => {
        try {
            const user = await getCurrentUser();
            if (user) {
                console.log(user);
                setLoggedInUser(user);
            }
        } catch (error) {
            console.log('error checking authenticated user', error);
        }
    };

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
        setFeedbackMsg('');
    };

    const handleLogin = async (event) => {
        event.preventDefault();

        // Check if a user is already authenticated
        if (loggedInUser) {
            setFeedbackMsg("User is already signed in.");
            // You can redirect or handle the case of an already authenticated user here.
            return;
        }

        try {
            const { isSignedIn, nextStep } = await signIn({
                username: email,
                password,
            });
            if (isSignedIn) {
                setFeedbackMsg("Login successful!");

                // Retrieve and display user information
                try {
                    const user = await getCurrentUser();
                    if (user) {
                        setLoggedInUser(user);
                    }
                } catch (userError) {
                    console.log('error getting user information', userError);
                }

                // Redirect or update state as needed
            }
        } catch (error) {
            setFeedbackMsg("Invalid username or password. Please try again.");
            console.log('error signing in', error);
        }
    };

    const handleSignUp = async (event) => {
        event.preventDefault();
        if (password !== repeatPassword) {
            setFeedbackMsg("Passwords don't match.");
            return;
        }
        try {
            const signUpResponse = await signUp({
                username: email,
                password,
                attributes: {
                    email, // Optional: If you wish to add additional attributes like email
                }
            });

            console.log(signUpResponse);
            setFeedbackMsg("Sign-up successful! Please check your email to confirm your account.");
            setActiveTab(2);

            // After successful signUp, call the createPerformer GraphQL mutation
            const performerInput = {
                input: {
                    emailAddress: email,
                    userID: signUpResponse.userSub, // Assuming userSub can be used as the userID
                    active: true // Set additional fields as necessary
                }
            };


            const CreatePerformerInput = {
                emailAddress: email,
                userID: "123456", // Assuming userSub can be used as the userID
                active: true // Set additional fields as necessary
            };

            const client = generateClient();

            const newPerformer = await client.graphql({
                query: createPerformer,
                variables: { input: CreatePerformerInput }
            });
            console.log('New performer created:', newPerformer);

        } catch (error) {
            // Error handling
            console.log('error signing up:', error);
            setFeedbackMsg(error.message);
        }
    };

    const handleSignUpConfirmation = async () => {
        try {
            const { isSignUpComplete } = await confirmSignUp({
                username: email,
                confirmationCode,
            });

            if (isSignUpComplete) {
                setFeedbackMsg("Account confirmed successfully!");
            }
        } catch (error) {
            console.log('error confirming sign up', error);
            setFeedbackMsg("Invalid confirmation code. Please try again.");
        }
    };

    return (
        <Container maxWidth="sm" sx={{ border: '1px solid #011923', borderRadius: '8px', mt: 4, p: 2 }}>
            <Tabs value={activeTab} onChange={handleTabChange} centered sx={{ borderBottom: `2px solid #fda225` }}>
                <Tab label="Login" sx={{ '&.Mui-selected': { color: '#fda225' } }} />
                <Tab label="SignUp" sx={{ '&.Mui-selected': { color: '#fda225' } }} />
                <Tab label="Confirmation" sx={{ '&.Mui-selected': { color: '#fda225' } }} />
            </Tabs>
            {activeTab === 0 && (
                <Box component="form" onSubmit={handleLogin} sx={{ mt: 2 }}>
                    <TextField
                        fullWidth
                        label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        margin="normal"
                    />
                    <TextField
                        fullWidth
                        label="Password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        margin="normal"
                    />
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 2, bgcolor: '#011923', '&:hover': { bgcolor: '#03314b' } }}>
                        Login
                    </Button>
                    {feedbackMsg && <Typography color="error" sx={{ mt: 2 }}>{feedbackMsg}</Typography>}
                </Box>
            )}
            {activeTab === 1 && (
                <Box component="form" onSubmit={handleSignUp} sx={{ mt: 2 }}>
                    <TextField
                        fullWidth
                        label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        margin="normal"
                    />
                    <TextField
                        fullWidth
                        label="Password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        margin="normal"
                    />
                    <TextField
                        fullWidth
                        label="Repeat Password"
                        type="password"
                        value={repeatPassword}
                        onChange={(e) => setRepeatPassword(e.target.value)}
                        margin="normal"
                    />
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 2, bgcolor: '#011923', '&:hover': { bgcolor: '#03314b' } }}>
                        Sign Up
                    </Button>
                    {feedbackMsg && <Typography color="error" sx={{ mt: 2 }}>{feedbackMsg}</Typography>}
                </Box>
            )}
            {activeTab === 2 && (
                <Box component="form" onSubmit={handleSignUpConfirmation} sx={{ mt: 2 }}>
                    <TextField
                        fullWidth
                        label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        margin="normal"
                    />
                    <TextField
                        fullWidth
                        label="Confirmation Code"
                        value={confirmationCode}
                        onChange={(e) => setConfirmationCode(e.target.value)}
                        margin="normal"
                    />
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 2, bgcolor: '#011923', '&:hover': { bgcolor: '#03314b' } }}>
                        Confirm
                    </Button>
                    {feedbackMsg && <Typography color="error" sx={{ mt: 2 }}>{feedbackMsg}</Typography>}
                </Box>
            )}
        </Container>
    );
}

export default LoginSignUp;
