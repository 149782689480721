// Footer.js

import React from 'react';
import { Box, Typography, Container, Grid, Link } from '@mui/material';

function Footer() {
    return (
        <Box bgcolor="#011923" color="white" py={3}>
            <Container maxWidth="lg">
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" color="inherit">About Us</Typography>
                        <Typography variant="body2" color="inherit" gutterBottom>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        </Typography>
                        <Link href="#" color="inherit">Learn more</Link>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" color="inherit">Contact</Typography>
                        <Typography variant="body2" color="inherit" gutterBottom>
                            Email: info@stagestars.com
                        </Typography>
                        <Typography variant="body2" color="inherit">
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" color="inherit">Follow Us</Typography>
                        <Link href="#" color="inherit">Facebook</Link><br />
                        <Link href="#" color="inherit">Twitter</Link><br />
                        <Link href="#" color="inherit">Instagram</Link>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default Footer;
