import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemText, useTheme, useMediaQuery, Drawer, Typography, Box, IconButton } from '@mui/material';
import StageStarsLogo from '../images/StageStarsLogo.png';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import StarIcon from '@mui/icons-material/Star';
import SettingsIcon from '@mui/icons-material/Settings';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import MenuIcon from '@mui/icons-material/Menu'; // Hamburger menu icon
import ExitToAppIcon from '@mui/icons-material/ExitToApp'; // Sign Out icon
import { signOut } from 'aws-amplify/auth';


function PortalSidebar({ setSelectedItem, mobileMenuOpen, handleSidebarToggle }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleItemClick = (item) => {
        setSelectedItem(item);
    };

    const handleSignOut = async () => {
        try {
            await signOut();
            window.location.reload(); // or navigate the user to the sign-in page
        } catch (error) {
            console.error('Error signing out: ', error);
        }
    };

    return (
        <Drawer
            variant={isMobile ? 'temporary' : 'persistent'}
            anchor="left"
            open={true}
            onClose={() => setSelectedItem(null)}
            ModalProps={{
                keepMounted: true,
            }}
            sx={{
                width: mobileMenuOpen ? '50px' : '240px',
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: mobileMenuOpen ? '50px' : '240px',
                    backgroundColor: '#011923',
                    overflowX: mobileMenuOpen ? 'hidden' : 'auto', // Hide scrollbar when collapsed

                },
            }}
        >
            <Box display="flex" flexDirection="column" alignItems="center" p={3}>
                <img src={StageStarsLogo} alt="StageStars Logo" style={{ maxWidth: mobileMenuOpen ? '50px' : '150px', marginTop: '50px' }} />
            </Box>
            <IconButton
                color="inherit"
                aria-label="open mobile menu"
                onClick={handleSidebarToggle}
                sx={{
                    color: '#fff',
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                }}
            >
                <MenuIcon />
            </IconButton>
            <List sx={{ padding: 0 }}>
                <ListItem button onClick={() => handleItemClick('Profile')}>
                    <AnalyticsIcon sx={{ color: 'white', marginRight: 1 }} />
                    <ListItemText primary={!mobileMenuOpen ? "Overview" : ""} className="custom-list-item" />
                </ListItem>
                <ListItem button onClick={() => handleItemClick('Profile')}>
                    <AccountCircleIcon sx={{ color: 'white', marginRight: 1 }} />
                    <ListItemText primary={!mobileMenuOpen ? "Profile" : ""} className="custom-list-item" />
                </ListItem>
                <ListItem button onClick={() => handleItemClick('Profile')}>
                    <StarIcon sx={{ color: 'white', marginRight: 1 }} />
                    <ListItemText primary={!mobileMenuOpen ? "Act Information" : ""} className="custom-list-item" />
                </ListItem>
                <ListItem button onClick={() => handleItemClick('Settings')}>
                    <SettingsIcon sx={{ color: 'white', marginRight: 1 }} />
                    <ListItemText primary={!mobileMenuOpen ? "Settings" : ""} className="custom-list-item" />
                </ListItem>
                {/* Sign Out Icon with Text */}

            </List>
            <ListItem button onClick={handleSignOut} sx={{ position: 'absolute', bottom: '20px', width: 'auto' }}>
                <ExitToAppIcon sx={{ color: 'white' }} />
                <ListItemText primary="Sign Out" sx={{ color: 'white', display: mobileMenuOpen ? 'none' : 'block' }} />
            </ListItem>
        </Drawer>
    );
}

export default PortalSidebar;
