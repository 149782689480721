import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import HomePage from './HomePage'; // Adjust the path as needed
import Portal from './Portal'; // Adjust the path as needed
import { ThemeProvider, createTheme } from '@mui/material/styles';

// Import other pages as needed
const theme = createTheme();


function App() {
    return (
        <ThemeProvider theme={theme}>

            <Router>
                <div>
                    <Routes>
                        <Route path="/portal" element={<Portal />} />
                        <Route path="/" element={<HomePage />} />
                        {/* Define routes for other pages */}
                    </Routes>
                </div>
            </Router>
        </ThemeProvider>

    );
}

export default App;
