// Portal.js
import React, { useEffect, useState } from 'react';
import { getCurrentUser } from 'aws-amplify/auth';
import {
  Container,
  Typography,
  AppBar,
  Toolbar,
  Grid,
  CssBaseline,
} from '@mui/material';
import './Portal.css';
import LoginSignUp from './components/LoginSignUp';
import PortalSidebar from './components/PortalSidebar';
import Profile from './components/Profile';
import Settings from './components/Settings';

function Portal() {
  const [user, setUser] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    // Check if a user is already authenticated on page load
    checkAuthenticatedUser();
  }, []);

  const checkAuthenticatedUser = async () => {
    try {
      const user = await getCurrentUser();
      if (user) {
        console.log(user);
        setUser(user);
      }
    } catch (error) {
      console.log('error checking authenticated user', error);
    }
  };

  const handleSidebarToggle = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const renderSelectedComponent = () => {
    switch (selectedItem) {
      case 'Profile':
        return <Profile user={user} />;
      case 'Settings':
        return <Settings />;
      // Add more cases for other menu items and their respective components
      default:
        return null;
    }
  };

  return (
    <>
      <CssBaseline /> {/* Apply baseline styles to the entire app */}
      <Container
        maxWidth="lg"
        style={{
          backgroundColor: '#fff',
          padding: '20px',
          marginLeft: mobileMenuOpen ? '50px' : '240px',
          transition: 'margin 0.3s ease-in-out',
        }}
      >
        {user ? (
          <Grid container style={{ border: '1px solid red' }}>
            <Grid item xs={12} sm={3}>
              <PortalSidebar
                setSelectedItem={setSelectedItem}
                mobileMenuOpen={mobileMenuOpen}
                handleSidebarToggle={handleSidebarToggle}
              />

            </Grid>
            <Grid item xs={12} sm={9}>
              <AppBar position="static" color="transparent" elevation={0}>
                <Toolbar>

                </Toolbar>
              </AppBar>
              <Typography variant="h4">Welcome, {user.username}</Typography>
              {renderSelectedComponent()}
            </Grid>
          </Grid>
        ) : (
          <LoginSignUp />
        )}
      </Container>
    </>
  );
}

export default Portal;
