import React, { useState } from 'react';
import { useTheme, useMediaQuery, Container, Typography, Box, Grid, Paper, AppBar, Toolbar, Button, IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom'; // Import Link
import MenuIcon from '@mui/icons-material/Menu';
import './HomePage.css';
import StageStarsLogo from './images/StageStarsLogo.png';
import HeroImage from './images/stage-stars-hero1.png';
import Performer from './components/Performer';
import SearchFilters from './components/SearchFilters';
import PerformerImageMime from './images/mime.png';
import PerformerImageMagic from './images/magician.png';
import PerformerImageFire from './images/fire.png';
import Footer from './components/Footer';


const performers = [
  {
    name: 'Performer One',
    rate: '$100/hr',
    description: 'A talented performer with a captivating presence.',
    image: PerformerImageMime,
    rating: 4.5,
    location: 'New York',
    country: 'USA',
    zipCode: '10001',
    performerType: ['Mime']
  },
  {
    name: 'Performer Two',
    rate: '$150/hr',
    description: 'Experienced in various styles and genres.',
    image: PerformerImageMagic,
    rating: 4,
    location: 'Los Angeles',
    country: 'USA',
    zipCode: '90001',
    performerType: ['Magician']
  },
  {
    name: 'Performer Three',
    rate: '$120/hr',
    description: 'Brings unique performances to every stage.',
    image: PerformerImageFire,
    rating: 4.2,
    location: 'Chicago',
    country: 'USA',
    zipCode: '60601',
    performerType: ['Fire Breather']
  }
  // Add more performers as needed
];

function HomePage() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [searchCriteria, setSearchCriteria] = useState({});
  const [filteredPerformers, setFilteredPerformers] = useState(performers);

  const handleSearch = (newSearchCriteria) => {
    setFilteredPerformers(filterPerformers(newSearchCriteria));
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const filterPerformers = (criteria) => {
    return performers.filter(performer => {
      return (
        (!criteria.country || performer.country === criteria.country) &&
        (!criteria.zipCode || performer.zipCode === criteria.zipCode) &&
        (!criteria.performerType || criteria.performerType.some(type => performer.performerType.includes(type)))
      );
    });
  };

  const drawer = (
    <List>
      {/* Map your list items to include Links. Adjust as needed for your app structure */}
      <ListItem button component={Link} to="/">
        <ListItemText primary="Home" />
      </ListItem>
      <ListItem button component={Link} to="/portal">
        <ListItemText primary="Login/Sign Up" />
      </ListItem>
      {/* Add other navigation items */}
    </List>
  );


  return (
    <Container maxWidth="lg" style={{ backgroundColor: '#fff', padding: '20px' }}>
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar>
          <img src={StageStarsLogo} alt="StageStars Logo" style={{ maxWidth: '150px', marginRight: 'auto' }} />
          {!isMobile ? (
            <>
              <Button color="inherit" component={Link} to="/">Home</Button>
              <Button color="inherit">Search</Button>
              <Button color="inherit">About Us</Button>
              <Button color="inherit">Contact Us</Button>
              <Button color="inherit" component={Link} to="/portal">Login/Sign Up</Button>
            </>
          ) : (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="temporary"
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {drawer}
      </Drawer>

      <Paper style={{ position: 'relative', height: '300px', marginBottom: '20px', overflow: 'hidden' }}>
        <Box style={{
          backgroundImage: `url(${HeroImage})`,
          backgroundSize: 'cover',
          height: '100%',
          filter: 'brightness(40%)'
        }} />
        <Typography variant="h4" className="transparent-text hero-text" style={{
          position: 'absolute',
          top: '50%',  // Center vertically
          left: '50%', // Center horizontally
          transform: 'translate(-50%, -50%)', // Adjust for centering
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'clip',
          background: `url(${HeroImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'rgba(255,255,255,0.4)',
          fontSize: '6rem',
          fontWeight: 'bold',
          fontFamily: '"Arial Black", Gadget, sans-serif',
          textShadow: '2px 2px 4px rgba(255, 255, 255, 0.2)'
        }}>
          BOOK A STAR
        </Typography>
      </Paper>

      <Box my={4} textAlign="center">
        <Typography variant="h4" gutterBottom>
          Discover the Perfect Performer for Your Event
        </Typography>
        <Typography variant="body1">
          At Stage Stars Booking, we connect you with an array of incredible performers, from the melodious singers to the enchanting mimes, and from energetic bands to the astonishing fire breathers. Whether you're planning a birthday party, a corporate event, or any special occasion, find and book the ideal local talent to turn your event into an unforgettable experience.
        </Typography>
      </Box>

      <Box my={4}>
        <Typography variant="h5" color="textPrimary" gutterBottom>
          Top Performers
        </Typography>
        <Grid container spacing={2}>
          {filteredPerformers.map((performer, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Performer {...performer} />
            </Grid>
          ))}
        </Grid>
      </Box>



      <Box my={4}>
        <Typography variant="h5" color="textPrimary" gutterBottom>
          Find Performers
        </Typography>
        <SearchFilters onSearch={handleSearch} />

        <Grid container spacing={2}>
          {filteredPerformers.map((performer, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Performer {...performer} />
            </Grid>
          ))}
          {filteredPerformers.map((performer, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Performer {...performer} />
            </Grid>
          ))}
          {filteredPerformers.map((performer, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Performer {...performer} />
            </Grid>
          ))}
        </Grid>
      </Box>

      <Footer />

    </Container>
  );
}

export default HomePage;
