/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPerformerMedia = /* GraphQL */ `
  mutation CreatePerformerMedia(
    $input: CreatePerformerMediaInput!
    $condition: ModelPerformerMediaConditionInput
  ) {
    createPerformerMedia(input: $input, condition: $condition) {
      id
      mediaType
      mediaURL
      deleted
      performerID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePerformerMedia = /* GraphQL */ `
  mutation UpdatePerformerMedia(
    $input: UpdatePerformerMediaInput!
    $condition: ModelPerformerMediaConditionInput
  ) {
    updatePerformerMedia(input: $input, condition: $condition) {
      id
      mediaType
      mediaURL
      deleted
      performerID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePerformerMedia = /* GraphQL */ `
  mutation DeletePerformerMedia(
    $input: DeletePerformerMediaInput!
    $condition: ModelPerformerMediaConditionInput
  ) {
    deletePerformerMedia(input: $input, condition: $condition) {
      id
      mediaType
      mediaURL
      deleted
      performerID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPerformerDetails = /* GraphQL */ `
  mutation CreatePerformerDetails(
    $input: CreatePerformerDetailsInput!
    $condition: ModelPerformerDetailsConditionInput
  ) {
    createPerformerDetails(input: $input, condition: $condition) {
      id
      hourlyRate
      dailyRate
      description
      raiting
      location
      country
      zipCode
      performerType
      Performer {
        id
        mediaType
        mediaURL
        deleted
        performerID
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      performerDetailsPerformerId
      __typename
    }
  }
`;
export const updatePerformerDetails = /* GraphQL */ `
  mutation UpdatePerformerDetails(
    $input: UpdatePerformerDetailsInput!
    $condition: ModelPerformerDetailsConditionInput
  ) {
    updatePerformerDetails(input: $input, condition: $condition) {
      id
      hourlyRate
      dailyRate
      description
      raiting
      location
      country
      zipCode
      performerType
      Performer {
        id
        mediaType
        mediaURL
        deleted
        performerID
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      performerDetailsPerformerId
      __typename
    }
  }
`;
export const deletePerformerDetails = /* GraphQL */ `
  mutation DeletePerformerDetails(
    $input: DeletePerformerDetailsInput!
    $condition: ModelPerformerDetailsConditionInput
  ) {
    deletePerformerDetails(input: $input, condition: $condition) {
      id
      hourlyRate
      dailyRate
      description
      raiting
      location
      country
      zipCode
      performerType
      Performer {
        id
        mediaType
        mediaURL
        deleted
        performerID
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      performerDetailsPerformerId
      __typename
    }
  }
`;
export const createPerformer = /* GraphQL */ `
  mutation CreatePerformer(
    $input: CreatePerformerInput!
    $condition: ModelPerformerConditionInput
  ) {
    createPerformer(input: $input, condition: $condition) {
      id
      active
      firstName
      PerformerMedias {
        items {
          id
          mediaType
          mediaURL
          deleted
          performerID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      lastName
      nextBillDate
      phoneNumber
      emailAddress
      userID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePerformer = /* GraphQL */ `
  mutation UpdatePerformer(
    $input: UpdatePerformerInput!
    $condition: ModelPerformerConditionInput
  ) {
    updatePerformer(input: $input, condition: $condition) {
      id
      active
      firstName
      PerformerMedias {
        items {
          id
          mediaType
          mediaURL
          deleted
          performerID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      lastName
      nextBillDate
      phoneNumber
      emailAddress
      userID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePerformer = /* GraphQL */ `
  mutation DeletePerformer(
    $input: DeletePerformerInput!
    $condition: ModelPerformerConditionInput
  ) {
    deletePerformer(input: $input, condition: $condition) {
      id
      active
      firstName
      PerformerMedias {
        items {
          id
          mediaType
          mediaURL
          deleted
          performerID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      lastName
      nextBillDate
      phoneNumber
      emailAddress
      userID
      createdAt
      updatedAt
      __typename
    }
  }
`;
