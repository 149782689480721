import React, { useState } from 'react';
import { Box, TextField, FormControl, InputLabel, Select, MenuItem, Grid, Checkbox, ListItemText, OutlinedInput, Button } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function SearchFilters({ onSearch }) {
    const [country, setCountry] = useState('');
    const [sortBy, setSortBy] = useState('');
    const [performerType, setPerformerType] = useState([]);
    const [zipCode, setZipCode] = useState('');
    const performerTypes = ['Magicians', 'Mimes', 'Singers/Bands', 'Fire Breathers', 'Dancers', 'Comedians'];

    const handleCountryChange = (event) => {
        setCountry(event.target.value);
    };

    const handleSortChange = (event) => {
        setSortBy(event.target.value);
    };

    const handlePerformerTypeChange = (event) => {
        setPerformerType(event.target.value);
    };

    const handleZipCodeChange = (event) => {
        setZipCode(event.target.value);
    };

    const handleSearchClick = () => {
        onSearch({ country, sortBy, performerType, zipCode });
    };

    return (
        <Box my={4}>
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} sm={6} md={3}>
                    <TextField 
                        fullWidth 
                        label="ZIP / Post Code" 
                        variant="outlined" 
                        value={zipCode}
                        onChange={handleZipCodeChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                        <InputLabel>Country</InputLabel>
                        <Select value={country} onChange={handleCountryChange} label="Country">
                            <MenuItem value="USA">USA</MenuItem>
                            <MenuItem value="UK">UK</MenuItem>
                            <MenuItem value="Canada">Canada</MenuItem>
                            {/* Add more countries as needed */}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                        <InputLabel>Sort By</InputLabel>
                        <Select value={sortBy} onChange={handleSortChange} label="Sort By">
                            <MenuItem value="distance">Distance</MenuItem>
                            <MenuItem value="price">Price</MenuItem>
                            <MenuItem value="rating">Rating</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                        <InputLabel>Performer Type</InputLabel>
                        <Select
                            multiple
                            value={performerType}
                            onChange={handlePerformerTypeChange}
                            input={<OutlinedInput label="Performer Type" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                        >
                            {performerTypes.map((type) => (
                                <MenuItem key={type} value={type}>
                                    <Checkbox checked={performerType.indexOf(type) > -1} />
                                    <ListItemText primary={type} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Button variant="outlined" onClick={handleSearchClick}>Search</Button>
                </Grid>
            </Grid>
        </Box>
    );
}

export default SearchFilters;
